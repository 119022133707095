<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2023-01-13 11:37:40
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-18 14:43:23
 * @FilePath: \dataview-next\src\manage-views\components\share\Login.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="share-login" v-if="!isH5" v-loading="loading">
    <div >
      <img style="width:165px;height:40px;margin-bottom:32px" src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/2b08c41ade9817f3958fc9cf6b503292.png" alt="">
    </div>
    <div class="share-form">
      <div class="share-title">
        <div class="title"> 文件分享:</div>
        <div class="share-name">
          <div class="icon">
            <svg
						v-if="+pageInfo?.share_type === 1"
						class="iconfont-svg"
						aria-hidden="true"
            width="14" height="14"
					>
              <use xlink:href="#iconzhishiku2" />
            </svg>
            <svg
              v-else
              class="iconfont-svg"
              aria-hidden="true"
              width="14" height="14"
            >
              <use xlink:href="#iconwenjianjia3" />
            </svg>
          </div>
          <h1>{{
							pageInfo?.repository.name.length > 10
								? pageInfo?.repository.name.substring(0, 10) + '...'
								: pageInfo?.repository.name
						}}等{{ fileNum ? fileNum : '多' }}个文件</h1>
          <p v-if="starttime>endtime" :class="{'apply-shake':shake}">(文件已过期)</p>
        </div>
      </div>
      <div class="share-pwd">
        <el-input placeholder="请输入提取码" v-model="pwd" :class="{'apply-shake':shakeInput}"></el-input>
      </div>
      <div class="foot-btn">
        <button @click="login">提取文件</button>
      </div>
    </div>
  </div>
  <AppLogin v-else></AppLogin>
</template>

<script>
import { repositoryLoginDetail,repositoryShareLogin } from '@/saas-apis/konwledgeBase.js';
import AppLogin from '@/manage-views/components/share/appShare/Login.vue'

export default {
  components: {
    AppLogin
  },
  props: {},
  data() {
    return {
      pageInfo:null,
      pwd:'',
      uuid:'',
      starttime:new Date(),
      endtime:null,
      shake:false,
      isH5:false,
      shakeInput:'',
      loading:false,
      fileNum: '',
    };
  },
  watch: {},
  computed: {},
  methods: {
    getData(){
      this.loading = true;
      this.uuid = location.href.split('?')[1]
      repositoryLoginDetail({uuid:this.uuid}).then(res=>{
        if(res.data.code===200){
          this.pageInfo = res.data.data
          this.endtime = new Date(Date.parse(res.data.data.expiration_time))
          this.loading = false;
          if (+this.pageInfo.share_type === 2 && this.pageInfo.file_id) {
						const arr = this.pageInfo.file_id.split(',');
						if (arr && arr.length) {
							this.fileNum = arr.length;
						}
					}
        }
      })
    },
    login(){
      if(this.starttime > this.endtime){
        this.shake = true;
        setTimeout(()=>{
          this.shake = false
        },820)
        return
      }
      if(!this.pwd){
        this.shakeInput = true;
        setTimeout(()=>{
          this.shakeInput = false
        },820)
        return
      }
      repositoryShareLogin({uuid:this.uuid,code:this.pwd}).then(res=>{
        if(res.data.code==200){
          let pageInfoArr = JSON.parse(localStorage.getItem('pageInfo'))??[]
          if(pageInfoArr.length){
            let canPush = true
            pageInfoArr.forEach((item)=>{
              if(item.uuid === this.pageInfo.uuid){
                canPush = false
              }
            })
            if(canPush){
              pageInfoArr.push(res.data.data)
            } 
          }else{
            pageInfoArr.push(res.data.data)
          }
          localStorage.setItem('pageInfo',JSON.stringify(pageInfoArr))
          this.$router.push(
            {
              name:'shareDetail',
              query: {uuid:this.pageInfo.uuid}
            }
          )
        }

      })
    }
  },
  created() {
    this.getData()
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      // 当前设备是移动设备
      this.isH5 = true
    }else{
      this.isH5 = false
    }
  },
};
</script>
<style lang="less" scoped>
:deep(.el-input){
  height: 40px;
  background: transparent;
  .el-input__inner{
    height: 100%;
    &::placeholder{
      color: #A9ADB8;
      font-size: 14px;
    }
  }
}
.share-login{
  width: 100%;
  height: 100%;
  background: #f4f6fa;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 224px;
  .share-form{
    width: 480px;
    height: 296px;
    background: #fff;
    border-radius: 8px;
    padding: 48px 56px 61px;
    box-sizing: border-box;
    .share-title{
      // display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .share-img{
        margin-top: 40px;
      }
      .title{
        color: #161C1F;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
      }
      .share-name{
        display: flex;
        align-items: center;
        margin-top: 5.5px;
        // justify-content: center;
        .icon{
          height: 14px;
        }
        h1{
          margin-left: 12px;
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: 22px;
          color: #161C1F;
        }
        p{
          color: red;
        }

      }

    }
    .share-pwd{
      padding-top: 33.5px;
      position: relative;
      .pwd-title{
        font-size: 14px;
        color: #333333;
        line-height: 1.5;
      }
      .input{
        font-family: 'Microsoft Yahei';
        font-size: 16px;
        color: #333333;
        line-height: 1.2;
        display: block;
        width: 390px;
        height: 55px;
        background: transparent;
        padding: 0 7px 0 43px;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #292b2c;
      }
      span{
        position: absolute;
        display: block;
        width: 100%;
        bottom: 12px;
        left: 0;
        pointer-events: none; 
        i{
          font-size: 30px;
        }
      }
    }
    .foot-btn{
      width: 390px;
      height: 50px;
      border-radius: 25px;
      padding-top: 20px;
      button{
        border: none;
        color: #FFFFFF;
        width: 368px;
        height: 40px;
        background: #337EFF;
        border-radius: 6px;
      }
    }
  }
}

@keyframes shake {
		10%,
		90% {
			transform: translate3d(-1px, 0, 0);
		}

		20%,
		80% {
			transform: translate3d(2px, 0, 0);
		}
		
		20%,
		50%,
		70% {
			transform: translate3d(-4px, 0, 0);
		}

		40%,
		60% {
			transform: translate3d(4px, 0, 0);
		}
	}

	.apply-shake {
		animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	}

</style>