<!--
 * @Author: zqy
 * @Date: 2023-01-17 09:46:01
 * @LastEditTime: 2023-01-18 17:08:13
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
	<div
		class="app-share-login"
		v-loading="loading"
	>
		<header>
			<p class="title">请输入提取码</p>
		</header>
		<article>
			<section class="share-info">
				<img
					src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/2b08c41ade9817f3958fc9cf6b503292.png"
					alt=""
				/>
				<p class="info-title">
					<svg
						v-if="+pageInfo?.share_type === 1"
						class="iconfont-svg"
						aria-hidden="true"
					>
						<use xlink:href="#iconzhishiku2" />
					</svg>
					<svg
						v-else
						class="iconfont-svg"
						aria-hidden="true"
					>
						<use xlink:href="#iconwenjianjia3" />
					</svg>
					<span
						>{{
							pageInfo?.repository.name.length > 10
								? pageInfo?.repository.name.substring(0, 10) + '...'
								: pageInfo?.repository.name
						}}等{{ fileNum ? fileNum : '多' }}个文件</span
					>
				</p>
			</section>
			<section class="share-form">
				<input
					class="input"
					v-model="pwd"
					placeholder="请输入提取码"
				/>
				<div class="foot-btn">
					<button
						:style="!pwd || !days ? 'opacity: 0.5;' : ''"
						:disabled="!pwd || !days"
						@click="login"
					>
						提取文件
					</button>
				</div>
				<p
					class="day-info"
					v-if="days"
				>
          <span v-if="forever">
            永久有效
          </span>
          <span v-else>
            {{ days }}天后过期
          </span>
				</p>
				<p
					class="day-info"
					v-else
				>
					链接已失效
				</p>
			</section>
		</article>
	</div>
</template>
<script>
import { repositoryLoginDetail, repositoryShareLogin } from '@/saas-apis/konwledgeBase.js';
// import AppLogin from '@/manage-views/components/share/appShare/Login.vue'

export default {
	data() {
		return {
			pageInfo: null,
			pwd: '',
			uuid: '',
			days: '',
			loading: false,
			fileNum: '',
      forever:false,
		};
	},
	methods: {
		getData() {
			this.uuid = location.href.split('?')[1];
			this.loading = true;
			repositoryLoginDetail({ uuid: this.uuid }).then(res => {
				this.loading = false;
				if (res.data.code === 200) {
					const data = res.data.data;
					if (data.expiration_time) {
						const date = data.expiration_time.replace(/-/g, '/');
            if(data.expiration_time.includes('2099')){
              this.forever = true
            }
						const today = new Date().getTime();
						const expirationTime = new Date(date).getTime();
						let days = this.calcDay(expirationTime, today);
						this.days = days < 0 ? '' : days;
					}
					if (+data.share_type === 2 && data.file_id) {
						const arr = data.file_id.split(',');
						if (arr && arr.length) {
							this.fileNum = arr.length;
						}
					}
					this.pageInfo = data;
				}
			});
		},
		login() {
			repositoryShareLogin({ uuid: this.uuid, code: this.pwd }).then(res => {
				if (+res.data.code === 200) {
					let pageInfoArr = JSON.parse(localStorage.getItem('pageInfo')) ?? [];
					if (pageInfoArr.length) {
						let canPush = true;
						pageInfoArr.forEach(item => {
							if (item.uuid === this.pageInfo.uuid) {
								canPush = false;
							}
						});
						if (canPush) {
							pageInfoArr.push(res.data.data);
						}
					} else {
						pageInfoArr.push(res.data.data);
					}
					localStorage.setItem('pageInfo', JSON.stringify(pageInfoArr));
					this.$router.push({
						name: 'appshareDetail',
						query: {
							uuid: this.pageInfo.uuid,
							days: this.days,
              forever:this.forever
						}
					});
				}
			});
		},
		calcDay(timestamp1, timestamp2) {
			// 将时间戳相减获得差值（毫秒数）
			const differ = timestamp1 - timestamp2;
			/**
			 * @desc 毫秒数除以1000就转为秒数
			 * @desc 秒数除以60后取整，就是分钟（因为1分钟等于60秒）
			 * @desc 秒数除以3600后取整，就是小时（因为1小时等于3600秒）
			 * @desc 小时数除以24后取整，就是相差的天数
			 */
			const day = differ / 1000 / 60 / 60 / 24;
			return parseInt(day);
		}
	},
	mounted() {
		this.getData();
	}
};
</script>
<style lang="less" scoped>
.app-share-login {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	header {
		width: 100%;
		height: 44px;
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		background: #f4f6fa;
		.title {
			font-style: normal;
			font-weight: 500;
			font-size: 17px;
			color: #2a2f3d;
		}
	}
	article {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		background: #f4f6fa;
		.share-info {
			padding: 12px 17px;
			img {
				height: 30px;
			}
			.info-title {
				padding-top: 12px;
				padding-left: 10px;
				display: flex;
				align-items: center;
				.iconfont-svg {
					width: 16px;
					height: 16px;
					padding-right: 18px;
				}
				span {
					font-weight: 400;
					font-size: 15px;
					color: #2a2f3d;
				}
			}
		}
		.share-form {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #fff;
			border-radius: 16px 16px 0 0;
			padding-top: 80px;
			.input {
				width: 294px;
				height: 48px;
				background: #f4f6fa;
				border-radius: 48px;
				border: none;
				text-align: center;
				font-size: 16px;
				::placeholder {
					color: #a9adb8;
				}
			}
			.foot-btn {
				padding-top: 24px;
				button {
					width: 294px;
					height: 48px;
					background: #337eff;
					border-radius: 48px;
					border: none;
					font-weight: 500;
					font-size: 16px;
					color: #ffffff;
					cursor: pointer;
				}
			}
			.day-info {
				padding-top: 13px;
				color: #a9adb8;
			}
		}
	}
}
</style>
